import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'

export default function useGlobalSearchURLHashFilter() {
  const history = useHistory()
  const location = useLocation()
  const isFilterPanelOpen = useMemo(() => {
    if (location.hash.startsWith('#filter')) {
      return true
    }
    return false
  }, [location.hash])

  const toggleFilterPanel = useCallback((
  ) => {
    const search = location.search
    if (isFilterPanelOpen) {
      history.replace({ ...location, search, hash: '' })
    } else {
      history.replace({ ...location, search, hash: 'filter' })
    }
  }, [history, isFilterPanelOpen, location])

  return { isFilterPanelOpen, toggleFilterPanel }
}
