import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineFilterAltIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fillRule="evenodd" d="M6 3c0-.5523-.4477-1-1-1s-1 .4477-1 1v4H3c-.5523 0-1 .4477-1 1s.4477 1 1 1h4c.5523 0 1-.4477 1-1s-.4477-1-1-1H6V3Zm13-1c.5523 0 1 .4477 1 1v4h1c.5523 0 1 .4477 1 1s-.4477 1-1 1h-4c-.5523 0-1-.4477-1-1s.4477-1 1-1h1V3c0-.5523.4477-1 1-1ZM5 10c.5523 0 1 .4477 1 1v10c0 .5523-.4477 1-1 1s-1-.4477-1-1V11c0-.5523.4477-1 1-1Zm15 1c0-.5523-.4477-1-1-1s-1 .4477-1 1v10c0 .5523.4477 1 1 1s1-.4477 1-1V11ZM9 15c0-.5523.4477-1 1-1h4c.5523 0 1 .4477 1 1s-.4477 1-1 1h-1v5c0 .5523-.4477 1-1 1s-1-.4477-1-1v-5h-1c-.5523 0-1-.4477-1-1Zm4-12c0-.5523-.4477-1-1-1s-1 .4477-1 1v9c0 .5523.4477 1 1 1s1-.4477 1-1V3Z" clipRule="evenodd"/>
  </SvgIcon>
}

export default LineFilterAltIcon
