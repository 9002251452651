import React, { useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import TextButton from 'components/Luxkit/Button/TextButton'
import RadioInput from 'components/Luxkit/Radio/RadioInput'
import useToggle from 'hooks/useToggle'
import Breakpoint from 'components/Common/Breakpoint'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { OfferListSortOption, SORT_OPTION_RECOMMENDED } from 'constants/offerListFilters'
import { parseSearchString } from 'lib/url/searchUrlUtils'
import { replace } from 'connected-react-router'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import Modal from 'components/Luxkit/Modal/Modal'
import LineAngleUpIcon from 'components/Luxkit/Icons/line/LineAngleUpIcon'
import Group from 'components/utils/Group'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import { rem } from 'polished'
import FilterChip from 'components/Luxkit/Chips/FilterChip'

const RadioListItemsGroup = styled(Group)`
  padding-inline: ${rem(16)};
`

const OptionsWrapper = styled.div`
  position: relative;
`
interface Props {
  sortOptions: Array<OfferListSortOption>;
  windowSearch: string;
  condenseOnMobile?: boolean;
  replace: Utils.ActionDispatcher<typeof replace>,
  onOpen?: () => void;
  onSelect?: (option: OfferListSortOption) => void;
  roundedButton?: boolean
  showDropdown?: boolean
  showSelectedHighlight?: boolean
}

function OfferSortControl({
  sortOptions,
  windowSearch,
  condenseOnMobile,
  replace,
  onOpen,
  onSelect,
  roundedButton = false,
  showDropdown = false,
  showSelectedHighlight = false,
}: Props) {
  const { value: isOpenSort, toggle: toggleSort, off: closeSort } = useToggle()

  useEffect(() => {
    isOpenSort && onOpen?.()
  }, [isOpenSort, onOpen])

  const searchParams = parseSearchString(windowSearch)

  const currentOption = sortOptions.find(option => option.value === searchParams.sortBy) ?? SORT_OPTION_RECOMMENDED

  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((e) => {
    const value = e.currentTarget.value
    const newParams = new URLSearchParams(windowSearch)
    if (value === 'recommended') {
      // `recommended` is default. In this case, leave the param out entirely for consistency
      newParams.delete('sortBy')
    } else {
      newParams.set('sortBy', value)
    }
    replace({ search: newParams.toString() })
    closeSort()
    onSelect?.(sortOptions.find(option => option.value === value)!)
    window.scrollTo({ behavior: 'instant' as any, top: 0 })
  }, [windowSearch, replace, closeSort, onSelect, sortOptions])

  const sortInputs = sortOptions.map(option => <RadioInput
    key={option.value}
    checked={currentOption.value === option.value}
    onChange={onChange}
    name={option.label}
    value={option.value}
  >
    {option.label}
  </RadioInput>)

  const triggerRef = useRef<HTMLButtonElement>(null)

  const buttonContent = condenseOnMobile ? <>
    <CSSBreakpoint max="mobile" as="span">Sort</CSSBreakpoint>
    <CSSBreakpoint min="tablet" as="span">Sort: {currentOption.label}</CSSBreakpoint>
  </> : <>
    Sort: {currentOption.label}
  </>

  const selected = showSelectedHighlight && currentOption.value !== 'recommended'

  return (
    <OptionsWrapper>
      {roundedButton && <FilterChip
        selected={selected}
        size="medium"
        onClick={toggleSort}
        endIcon={isOpenSort ? <LineAngleUpIcon /> : <DropdownChevron />}
        ref={triggerRef}
      >
        {buttonContent}
      </FilterChip>}
      {!roundedButton && <TextButton kind="secondary" variant="dark" onClick={toggleSort} endIcon={isOpenSort ? <LineAngleUpIcon /> : <DropdownChevron/>} ref={triggerRef}>
        {buttonContent}
      </TextButton>}
      <Breakpoint min={showDropdown ? undefined : 'tablet'}>
        <DropdownList size="S" open={isOpenSort} onClose={closeSort} anchorRef={triggerRef} triggerRef={triggerRef}>
          <RadioListItemsGroup direction="vertical" gap={16}>
            {sortInputs}
          </RadioListItemsGroup>
        </DropdownList>
      </Breakpoint>
      {!showDropdown && <Breakpoint max="mobile">
        <Modal
          title="Sort by"
          onClose={closeSort}
          isOpen={isOpenSort}
          primaryActionText="Apply"
          onPrimaryActionClick={closeSort}
        >
          <RadioListItemsGroup direction="vertical" gap={16}>
            {sortInputs}
          </RadioListItemsGroup>
        </Modal>
      </Breakpoint>}
    </OptionsWrapper>
  )
}

const mapStateToProps = (state: App.State) => ({
  windowSearch: state.router.location.search,
})

const mapDispatchToProps = {
  replace,
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferSortControl)
