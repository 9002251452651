import TextButton from 'components/Luxkit/Button/TextButton'
import LineFilterAltIcon from 'components/Luxkit/Icons/line/LineFilterAltIcon'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { useInView } from 'react-intersection-observer'
import noop from 'lib/function/noop'
import { connect } from 'react-redux'
import Heading from 'components/Luxkit/Typography/Heading'
import config from 'constants/config'
import MapWindow from 'components/Search/MapWindow'
import Group from 'components/utils/Group'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const MobileFilterBar = styled(CSSBreakpoint)`
  display: flex;
  transition: box-shadow 0.2s, background-color 0.2s, width 0.2s, margin-left 0.2s, padding 0.2s;
  box-shadow: none;
  width: 100%;
  margin-left: 0;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 1;
  gap: ${rem(8)};

  > * {
    flex-grow: 1;
  }

  &.lifted {
    background-color: ${props => props.theme.palette.neutral.default.eight};
    box-shadow: ${props => props.theme.shadow.bottom.small};
    margin-left: ${rem(-20)};
  }
`

const StyledTextButton = styled(TextButton)`
  padding: ${rem(12)} 0;
  height: auto;
`

const Wrapper = styled(VerticalSpacer)`
  display: flex;
  flex-direction: column;
  width: 100%;
`

interface Props {
  mapViewPath?: string;
  listViewPath?: string;
  onSortAndFilterClick?: () => void;
  onViewSwitch?: () => void;
  hasSortOptions?: boolean;
  heading?: React.ReactNode;
  showFilterIcon?: boolean;
  isTours?: boolean;
}

function MobileOfferListFilters(props: Props) {
  const {
    mapViewPath,
    onSortAndFilterClick,
    onViewSwitch,
    hasSortOptions,
    heading,
    showFilterIcon = true,
    isTours = false,
  } = props

  const [toLiftRef, liftInView] = useInView()
  const sortAndFilterButtonText = hasSortOptions ? 'Sort & filter' : 'Filters'

  return (
    <CSSBreakpoint max="mobile">
      <Wrapper gap={16}>
        { heading &&
          <Heading variant="heading2" align="start">
            {heading}
          </Heading>
        }
        {(mapViewPath || isTours) &&
          <MobileFilterBar max="mobile" className={cn({ lifted: !liftInView })}>
            {!config.businessTraveller.isEnabled && (
              <>
                <StyledTextButton
                  kind="secondary"
                  variant="dark"
                  startIcon={showFilterIcon ? <LineFilterAltIcon /> : undefined}
                  onClick={onSortAndFilterClick}
                  data-testid="sort-and-filter-button"
                >
                  {sortAndFilterButtonText}
                </StyledTextButton>
                {mapViewPath &&
                  <StyledTextButton
                    kind="secondary"
                    variant="dark"
                    to={mapViewPath}
                    startIcon={<LineMapIcon />}
                    onClick={onViewSwitch}
                    data-testid="map-view-button"
                  >
                    Map view
                  </StyledTextButton>
                  }
              </>
            )}
            {config.businessTraveller.currentAccountMode === 'business' && (
              <Group direction="vertical" gap={16}>
                <StyledTextButton
                  kind="secondary"
                  variant="dark"
                  startIcon={showFilterIcon ? <LineFilterAltIcon /> : undefined}
                  onClick={onSortAndFilterClick}
                  data-testid="sort-and-filter-button"
                >
                  {sortAndFilterButtonText}
                </StyledTextButton>
                {mapViewPath && <MapWindow mapViewURL={mapViewPath} />}
              </Group>
            )}
          </MobileFilterBar>
        }
        <div ref={toLiftRef} />
      </Wrapper>
    </CSSBreakpoint>
  )
}

MobileOfferListFilters.defaultProps = {
  existingFilters: {},
  sortOptions: [],
  onSortOpen: noop,
  onSortSelect: noop,
  onViewSwitch: noop,
}

function mapStateToProps(state: App.State) {
  return {
    hasPopularHolidayTypes: state.destination.hotelPopularHolidayTypes.length > 0,
  }
}

export default connect(mapStateToProps)(MobileOfferListFilters)
