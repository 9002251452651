import { searchSwitchViewClick } from 'analytics/eventDefinitions'
import { addGTMEvent } from 'api/googleTagManager'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineMapIcon from 'components/Luxkit/Icons/line/LineMapIcon'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

interface Props {
  mapViewURL: string;
}

const StyledGroup = styled(Group)`
  background-image: url(${getSVGImageUrl('lebt-map-view-background-full')});
  background-position: center left;
  /* need to zoom in on the image */
  background-size: 150%;
  height: ${rem(80)};

  ${mediaQueryUp.tablet} {
    height: ${rem(90)};
  }
`

function MapWindow(props: Props) {
  const { mapViewURL } = props

  function handleClick() {
    addGTMEvent(searchSwitchViewClick())
  }

  return (
    <ClickableLink to={mapViewURL} onClick={handleClick}>
      <StyledGroup direction="horizontal" horizontalAlign="center" verticalAlign="center">
        <TextButton
          kind="secondary"
          variant="dark"
          startIcon={<LineMapIcon />}
          data-testid="map-view-button"
        >
          View on map
        </TextButton>
      </StyledGroup>
    </ClickableLink>
  )
}

export default MapWindow
