import React from 'react'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import Group from 'components/utils/Group'
import Typography from 'components/Luxkit/Typography/Typography'
import FormatCurrency from '../FormatCurrency'
import { FilterPanelCheckItem } from './FilterPanelCheckboxGroup'

interface Props {
  name: string;
  item: FilterPanelCheckItem;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  titlecase?: boolean;
}

function FilterPanelCheckboxGroupItem(props: Props) {
  const {
    onChange,
    name,
    item,
    defaultChecked,
    checked,
    titlecase = false,
  } = props

  const showLabelOnly = item.price === undefined && !item.count
  const showCount = !!item.count && item.price === undefined
  const showPrice = item.price !== undefined

  return (
    <CheckboxInput
      key={item.value}
      name={name}
      value={item.value}
      size="large"
      onChange={onChange}
      defaultChecked={defaultChecked}
      checked={item.checked ?? checked}
      hidden={item.hidden}
      data-carrier={item.value}
    >
      {showCount && (
        <Group direction="horizontal" horizontalAlign="space-between">
          {item.label}
          <span>({item.count})</span>
        </Group>
      )}

      {showPrice && (
        <Group direction="horizontal" horizontalAlign="space-between" gap="0 4">
          {item.label}

          <span>
            <FormatCurrency
              value={item.price!}
              format="roundedDollar"
            />
          </span>
        </Group>
      )}

      {showLabelOnly && (
        <Typography format={titlecase ? 'titlecase' : undefined} as="span">
          {item.label}
        </Typography>
      )}
    </CheckboxInput>
  )
}

export default FilterPanelCheckboxGroupItem
