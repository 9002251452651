import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineAngleUpIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="m16.9999 13.4099-4.29-4.24a.999.999 0 0 0-.71-.2957.9994.9994 0 0 0-.71.2957l-4.24 4.24a1.0003 1.0003 0 0 0 0 1.42.9998.9998 0 0 0 1.41 0l3.54-3.54 3.54 3.54a.9999.9999 0 0 0 .7.29.999.999 0 0 0 .7101-.29 1.0002 1.0002 0 0 0 .0499-1.42Z"/>
  </SvgIcon>
}

export default LineAngleUpIcon
