import React, { useMemo, useCallback, ChangeEventHandler, useEffect } from 'react'
import { replace } from 'connected-react-router'
import { useAppDispatch } from 'hooks/reduxHooks'
import {
  getCheckInDateFromURLSearchParams,
  getCheckOutDateFromURLSearchParams,
  getPriceLteFromURLSearchParams,
  propertyToDestinationSearch,
  setSearchParamValue,
} from 'lib/url/searchUrlUtils'
import useBusinessTravellerBusinessBudgetFetcher from 'businessTraveller/hooks/useBusinessTravellerBusinessBudgetFetcher'
import useQueryParams from 'hooks/useQueryParams'
import { queryKeyPriceLte } from 'constants/url'
import RadioGroup from 'components/Luxkit/Radio/RadioGroup'
import RadioInput from 'components/Luxkit/Radio/RadioInput'

interface Props {
  search: string;
  hotelOfferList?: App.OfferList;
}

function BusinessTravellerWithinBudgetListFilter(props: Props) {
  const { search, hotelOfferList } = props
  const dispatch = useAppDispatch()
  const queryParams = useQueryParams()

  const { checkInDate, checkOutDate, priceLte } = useMemo(() => {
    return {
      checkInDate: getCheckInDateFromURLSearchParams(queryParams),
      checkOutDate: getCheckOutDateFromURLSearchParams(queryParams),
      priceLte: getPriceLteFromURLSearchParams(queryParams),
    }
  }, [queryParams])

  const { budgetData } = useBusinessTravellerBusinessBudgetFetcher({})

  const totalNights = checkOutDate?.diff(checkInDate, 'days')
  const totalBudget = useMemo(() => budgetData?.maxNightlyBudget && totalNights ? budgetData.maxNightlyBudget * totalNights : undefined, [budgetData?.maxNightlyBudget, totalNights])
  const tenOverBudget = useMemo(() => totalBudget ? Math.ceil(totalBudget * 1.1) : undefined, [totalBudget])
  const twentyOverBudget = useMemo(() => totalBudget ? Math.ceil(totalBudget * 1.2) : undefined, [totalBudget])

  const hasBudgets = totalBudget && tenOverBudget && twentyOverBudget

  useEffect(() => {
    if (hotelOfferList && budgetData?.status === 'success' && hasBudgets) {
      // If no offers with current filter, then change to "Show all" option
      if (priceLte && priceLte !== 10000 && hotelOfferList.offerIds.length === 0 && !hotelOfferList.fetching) {
        dispatch(replace({ search: setSearchParamValue(search, queryKeyPriceLte, 10000) }))
      } else if (!priceLte) {
        dispatch(replace({ search: setSearchParamValue(search, queryKeyPriceLte, tenOverBudget) }))
      }
    }
  }, [budgetData?.status, dispatch, hasBudgets, hotelOfferList, priceLte, search, tenOverBudget])

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    if (!hasBudgets) {
      return
    }

    let newPriceLte: number

    if (event.currentTarget.value === '0' && totalBudget) {
      newPriceLte = totalBudget
    } else if (event.currentTarget.value === '10') {
      newPriceLte = tenOverBudget
    } else if (event.currentTarget.value === '20') {
      newPriceLte = twentyOverBudget
    } else if (event.currentTarget.value === '10000') {
      // Setting price to 10000 for "Show all" option as removing the priceLte searchParam
      // will cause the useEffect logic to default priceLte to "10% above budget"
      newPriceLte = 10000
    } else {
      newPriceLte = tenOverBudget
    }

    dispatch(replace({ search: propertyToDestinationSearch(setSearchParamValue(search, queryKeyPriceLte, newPriceLte)) }))
  }, [hasBudgets, totalBudget, dispatch, search, tenOverBudget, twentyOverBudget])

  return (
    <RadioGroup>
      <RadioInput
        name="businessTravellerWithinBudget"
        value={0}
        onChange={handleChange}
        checked={totalBudget === priceLte}
        disabled={budgetData?.status !== 'success'}
      >
        Within budget
      </RadioInput>
      <RadioInput
        name="businessTravellerWithinBudget"
        value={10}
        onChange={handleChange}
        checked={tenOverBudget === priceLte}
        disabled={budgetData?.status !== 'success'}
      >
        Up to 10% above budget
      </RadioInput>
      <RadioInput
        name="businessTravellerWithinBudget"
        value={20}
        onChange={handleChange}
        checked={twentyOverBudget === priceLte}
        disabled={budgetData?.status !== 'success'}
      >
        Up to 20% above budget
      </RadioInput>
      <RadioInput
        name="businessTravellerWithinBudget"
        value={10000}
        onChange={handleChange}
        checked={priceLte === 10000}
        disabled={budgetData?.status !== 'success'}
      >
        Show all
      </RadioInput>
    </RadioGroup>
  )
}

export default BusinessTravellerWithinBudgetListFilter
